import Header from "../components/header/header";
import HeaderImage from "../images/contact-us.png";
import React from "react";

const Contact = () => {
    return (
        <>
            <header>
                <Header text="Contact Us" tag="contact" backgroundImage={HeaderImage}/>
            </header>
            <body>
            test
            <hr/>
            </body>
        </>
    );
}
export default Contact;