import React from "react";
import Header from "../components/header/header";
import HomeImage from '../images/ouhome.png';    /* rename image when changed : see tip ## */
import Logo from '../images/MDM-Logo.png';
import '../App.css';
import '../pages/home.css';

const Home = () => {
    return (
        <>
            <header>
                <span className="welcome">Join us February 28 - March 2, 2025 for the 7th annual</span>
                <Header text="MEDICAL DEVICE" text2="MAKE-A-THON" tag="mdm" backgroundImage={HomeImage} />
            </header>
            <body>
            <div className="about">
                <div>
                    <img src={Logo} alt={"MDM 2025 Logo"} loading="lazy"/>
                </div>
                <div>
                    <h1> About Us </h1>
                    <p>
                        The Medical Device Make-A-Thon is a 48-hour competition where teams are challenged to
                        design and prototype a medical device that solves a given clinical or medical problem.
                    </p>
                    <p>
                        Students from all over the country and various backgrounds of majors are invited to
                        participate in this fun filled weekend of networking and innovation.
                    </p>
                </div>
                {/*<div>*/}
                {/*    <img src={require("../images/48dark.png")} alt={"48 hours"}/>*/}
                {/*</div>*/}
            </div>
            <div className="more-info">
                <div className="info-box" id="workshop">
                    <h4>
                        Workshop
                    </h4>
                    <ul>
                        <li><span>Participate in a Solidworks workshop to design a 3D model of your device</span></li>
                        <li><span>Receive mentorship from industry experts to refine your design</span></li>
                    </ul>
                </div>
                <div className="info-box" id="network">
                    <h4>
                        Network
                    </h4>
                    <ul>
                        <li><span>Engage with industry professionals and university professors throughout the event</span></li>
                        <li><span>Build connections with mentors and judges who are experts in the field</span></li>
                    </ul>
                </div>
                <div className="info-box" id="compete">
                    <h4>
                        Compete
                    </h4>
                    <ul>
                        <li><span>Top 15 teams advance to the next round of judging</span></li>
                        <li><span>Showcase 3D-printed final prototypes to a panel of judges</span></li>
                        <li><span>Prize money awarded to the top 5 teams</span></li>
                    </ul>
                </div>
            </div>


            <h1> When & Where </h1>
            <h3> February 28th through March 2nd, 2025</h3>
            <h3> Hosted by the University of Oklahoma </h3>

            <h1> Join Us! </h1>

            <h2> Registration opens <br/> October 7th @ 8:00am! </h2>
            </body>
        </>
    );
};
export default Home;