import Header from "../components/header/header";
import HeaderImage from "../images/Team.png";
import React from "react";
import './team.css'
import '../App.css';

const Team = () => {
    return (
        <>
            <header>
                <Header text="Meet Our Team" tag="team" backgroundImage={HeaderImage}/>
            </header>
            <body>
            test
            <hr/>

            <div className="team">
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Luke Pauli"/>
                        <div className="member-info">
                            <h3>Director</h3>
                            <p>Luke Pauli</p>
                        </div>
                    </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Nathan Herrera"/>
                        <div className="member-info">
                            <h3>Head Coordinator</h3>
                            <p>Nathan Herrera</p>
                        </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Brendan O'Rourke"/>
                        <div className="member-info">
                            <h3>Head Coordinator</h3>
                            <p>Brendan O'Rourke</p>
                        </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Haley Perez"/>
                    <div className="member-info">
                        <h3>Head of Website Design</h3>
                        <p>Haley Perez</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Alonna Estell"/>
                    <div className="member-info">
                        <h3>Amaneunsis and Meeting Scheduler</h3>
                        <p>Alonna Estell</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Natasha Tarwirei"/>
                    <div className="member-info">
                        <h3>Treasurer</h3>
                        <p>Natasha Tarwirei</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Mia Gonzales"/>
                    <div className="member-info">
                        <h3>Head of Social Media</h3>
                        <h4>Logo and T-Shirt Designer</h4>
                        <p>Mia Gonzales</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="James Lowe"/>
                    <div className="member-info">
                        <h3>Event Coordinator</h3>
                        <h4>Fusion360 Instructor</h4>
                        <p>James Lowe</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Modeste Gihozo Rukundo"/>
                    <div className="member-info">
                        <h3>Event Coordinator </h3>
                        <p>Modeste Gihozo Rukundo</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Nosipho Mpanza"/>
                    <div className="member-info">
                        <h3>Event Coordinator</h3>
                        <p>Nosipho Mpanza</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Brian Baharestani"/>
                    <div className="member-info">
                        <h3>Head of Sponsorship</h3>
                        <p>Brian Baharestani</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Sydney Kirages"/>
                    <div className="member-info">
                        <h3>Sponsorship Coordinator</h3>
                        <p>Sydney Kirages</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Cayman Matson"/>
                    <div className="member-info">
                        <h3>Logistics Coordinator</h3>
                        <p>Cayman Matson</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Mecca Fisher"/>
                    <div className="member-info">
                        <h3>Judge and Mentor Coordinator</h3>
                        <p>Mecca Fisher</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Joshua Cunningham"/>
                    <div className="member-info">
                        <h3>Judge and Mentor Coordinator</h3>
                        <p>Joshua Cunningham</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Mohammad Sajawal"/>
                    <div className="member-info">
                        <h3>Outreach and Volunteer Coordinator</h3>
                        <p>Mohammad Sajawal</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Haley Taffe"/>
                    <div className="member-info">
                        <h3>Facilities Coordinator</h3>
                        <p>Haley Taffe</p>
                    </div>
                </div>
                <div className="team-member">
                    <img src="path-to-image.jpg" alt="Davin Rhule"/>
                    <div className="member-info">
                        <h3>Solid Works & 3D Printing Instructor</h3>
                        <p>Davin Rhule</p>
                    </div>
                </div>
            </div>
            </body>
        </>
    );
}
export default Team;